<template>
  <div id="desc-short" class="m-b m-t-nav">
      <div class="wrap tt-up p-l p-r ta-j">
            <h1>
is a music platform designed to discover and rank the best independent artists and music locally, nationally, and globally.
            </h1>
            <div class="d-f jc-fe">
                <router-link to="/about">Read more</router-link>
            </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'DescShort',
    data () {
        return {
        }
    },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#desc-short {
    h1 {
        font-weight: 400;
    }
}
</style>