<template>
<div class="pop-mess p-f tt-up p w-100 d-f jc-c ai-c bc-black bs-bb">
    <p class="q-blink">{{popMessText}}</p>
</div>
</template>

<script>
export default {
  name: 'PopMess',
  computed: {
    popMessText() {
        if (this.$store.state.popMessText) {
          return this.$store.state.popMessText
        } else {
          return 'Function under construction'
        }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.pop-mess {
    top: 45vh;
    z-index: 40;
    /* quick blink ani */
    .q-blink{animation: qblink .3s 3;}
    @keyframes qblink {
      0% {opacity: 0}
      49%{opacity: 0}
      50% {opacity: 1}
    }
}
</style>
