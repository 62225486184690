<template>
<div id="mobile-player">

	<div class="pp-show-wrap d-f ai-c jc-c w-100">
		<div 
		class="pp-show-button d-f ai-c jc-c m br-r p-half hover" 
		v-if="hidePlayer" 
		@click="togglePlayer">
			<!-- <IconBase icon-name="chevron-up"><IconChevronUp/></IconBase> -->
			<div class="d-f" :class="{ spinPlay: isPlaying }">
				<img :src="songCover" alt="">
			</div>
		</div>
	</div>

	<!-- <div 
	v-if="!hideMiniPlayer"
	class="mini-player p-f w-100 d-f ai-c jc-sb bs-bb p bc-brand b-top b-bottom">
		<div class="controls d-f tt-up of-h">
			<a class="m-r" @click="playCurrentSong()" v-show="!isPlaying && !isLoading">
				Play
			</a>
			<a class="m-r" @click="pauseSong()" v-show="isPlaying && !isLoading">
				Pause
			</a>
			<a class="m-r" v-show="isLoading">
				Loading...
			</a>
		</div>
		<div class="ws-pre tt-up of-h" @click="hidePlayer = false">
			<p>{{songTitle}}</p>	
		</div>
		<div class="dropdown d-f ai-c jc-fe" @click="hidePlayer = false">
			<IconBase icon-name="chevron-down" :w="'16'" :h="'16'"><IconChevronDown/></IconBase>
		</div>
	</div> -->

	<div 
	v-if="!hidePlayer" 
	class="pp-wrap w-100 h-100 bs-bb bc-brand">
		
		<div 
		@click="hidePlayer = true"
		class="d-f jc-fe p">
			<IconBase icon-name="cancel"><IconCancel/></IconBase>
		</div>

		<div class="d-f fd-c p m-b-children">

			<div class="img d-f jc-c ai-c">
				<img :src="songCover" alt="">
			</div>

			<div class="d-f ai-c fd-c tt-up">
				<p class="ws-pre">{{songTitle}}</p>
				<!-- <p  class="grey">{{songArtist}}</p> -->
				<!-- <router-link class="grey" :to="`/${songArtistUrl}`" @click="hidePlayer = true">{{songArtist}}</router-link> -->
				<div class="grey" @click="goToProfile">{{songArtist}}</div>
			</div>

			<div class="playing-progress">
				<div v-if="!isNaN(songDuration)" 
				class="track-duration">{{songTime | doubleDigits }}</div>
				<div v-else class="track-duration">{{'0:00'}}</div>

				<ProgressBar
				class="m-l m-r"
				:bar-height="16" 
				:bar-color="'#ffffff'" 
				:bar-shade-color="'#333333'" 
				:border-color="'#1c1c1c'"
				:intensity="1"
				:listen="true"
				:current-value="songTime" 
				:total-value="songDuration" 
				@seekedTo="seekPlayer"
				>
				</ProgressBar>

				<div v-if="!isNaN(songDuration)" 
				class="track-duration">{{songDuration | doubleDigits}}</div>
				<div v-else class="track-duration">{{'0:00'}}</div>

			</div>
			
			<div class="d-f tt-up w-100">
				<a class="m-r" @click="playPrevSong()">
					Prev
				</a>
				<a class="m-r" @click="playCurrentSong()" v-show="!isPlaying && !isLoading">
					Play
				</a>
				<a class="m-r" v-show="isLoading">
					Loading...
				</a>
				<a class="m-r" @click="pauseSong()" v-show="isPlaying && !isLoading">
					Pause
				</a>
				<a class="m-r" @click="stop()" v-show="isPlaying && !isLoading">
					Stop
				</a>
				<a class="" @click="playNextSong()">
					Next
				</a>
				<!-- <a class="loop m-r" @click="changeContinuousPlay(!activePlayer.continuousPlaybackStatus)" :class="activePlayer.continuousPlaybackStatus ? 'active-color' : 'inactive-color'">
					Loop
				</a> -->
			</div>

			<div class="d-f jc-fe tt-up w-100">
				<div class="like m-r" @click="likeInteractionLocally(songId)">
					<a v-bind:class="{ strike: userInteractions.like }">
						<span class="grey" v-if="!userInteractions.like">+</span>
						<span class="grey" v-if="userInteractions.like">–</span>
						<span>Like</span>
					</a>
					<!-- <a><span class="grey">+</span>Like</a> -->
				</div>
				<div class="playlist m-r" @click="$store.dispatch('togglePopMess')">
					<a><span class="grey">+</span>List</a>
				</div>
				<div class="comment" @click="$store.dispatch('togglePopMess')">
					<a><span class="grey">+</span>Comment</a>
				</div>
			</div>
			
			<!-- <div class="volume">
				<ProgressBar
				class="m-l"
				:bar-height="16" 
				:bar-color="'#ffffff'" 
				:bar-shade-color="'#333333'" 
				:intensity="1" 
				:listen="true"
				:current-value="songVolume" 
				:total-value="1"
				@seekedTo="changeVolume"
				>
				</ProgressBar>
			</div> -->

		</div>

	</div>
	
</div>
</template>

<script>
// import Vue from 'vue'
// import XnsSeekBar from 'xns-seek-bar'
// Vue.use(XnsSeekBar);
import PlayerMixin from '@/mixins/PlayerMixin'

import IconBase from '@/assets/icons/IconBase.vue'
import IconChevronDown from '@/assets/icons/IconChevronDown.vue'
import IconChevronUp from '@/assets/icons/IconChevronUp.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconPlay from '@/assets/icons/IconPlay.vue'

import ProgressBar from '@/components/player/ProgressBar'

import { mapGetters } from "vuex"

export default {
	name: 'MobilePlayer',
    props: [
		'userInteractions'
    ],
	components: {
		IconBase,
		IconChevronDown,
		IconChevronUp,
		IconCancel,
		IconPlay,
		ProgressBar,
	},
    mixins: [ PlayerMixin ],
	filters: {
		doubleDigits: function (val) {
		if (isNaN(val)) {
			return '00'
		} else {
			if (val < 60) {
			return val < 10 ? '0:0' + val.toFixed() : '0: ' + val.toFixed()
			} else {
			let seconds = (val % 60).toFixed() == 60 ? '00' : (val % 60).toFixed()
			let minutes = Math.floor(val / 60).toFixed()
			return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds)
			}
			}
		},
		secsToMinutes: function (val) {
		if (isNaN(val)) {
			return (val.toFixed() % 60) + ':' + Math.floor(val / 60)
		} else {
			return 0
		}
		}
	},
    data() {
        return {
            hidePlayer: false,
			hideMiniPlayer: false
        }
    },
    computed: {
		...mapGetters([
			'getUser',
			'getUserSettings'
		]),
		songArtist() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId].artist
        },
		songArtistUrl() {
			const encoded = encodeURIComponent(this.activePlaylist.songs[this.activePlayer.currentTrackId].artist).replace(/\./g, '%2E')
			return encoded
		},
        songTitle() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId].title
        },
        songId() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId]
        },
        songCover() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId].cover
        },
        isLoading() {
            return this.activePlayer.playerIsLoading
        },
        isPlaying() {
            return this.activePlayer.isPlaying
        },
        songDuration() {
            return this.activePlayer.currentTrackDuration
        },
        songTime() {
            return this.activePlayer.currentTrackTime
        },
        songVolume() {
            return this.activePlayer.volume
        },
    },
    methods: {
        reEmitSeekedToPlayer(payload) {
            return this.$emit('seekedToPlayer', payload)
        },
        reEmitSeekedToVolume(payload) {
            return this.$emit('seekedToVolume', payload)
        },
		likeInteractionLocally(songId) {
			this.likeInteraction(songId)
			if (this.getUser.loggedIn) {
				this.userInteractions.like = !this.userInteractions.like
			} else {
				return
			}
		},
		goToProfile() {
			this.$router.push(`/${this.songArtistUrl}`)
			this.hidePlayer = true
		},
		togglePlayer() {
            this.hidePlayer = !this.hidePlayer;
            this.$store.dispatch('hidePlayer');
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
#mobile-player {
    .mini-player {
        top: $nav-height;
		height: $nav-height;
		.controls, .dropdown {
			width: 100px;
		}
    }
    .pp-show-button {
        position: fixed;
		background-color: $black;
		border: 1px solid $grey;
        bottom: 0;
		padding: 4px;
		border-radius: 100%;
		img {
			width: calc(#{$p1} * 4);
			height: calc(#{$p1} * 4);
			border-radius: 100%;
		}
		&:hover {
			background-color: $brand-bg;
			border: 1px solid $grey;
		}
    }
    .pp-wrap {
        z-index: 20;
        position: fixed;
        top: $nav-height;
        .img {
			height: 40vh;
            img {
                width: 50vw;
                height: 50vw;
            }
        }
        .loop {
            .inactive-color {
                color: red;
            }
            .active-color {
                color: green;
            }
        }
        .playing-progress {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .track-duration {
                color: white;
            }
        }
        .volume {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

</style>
