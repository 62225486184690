<template>
  <div id="app" class="font">

    <router-view/>

    <!-- <LoginBar/> -->

    <Navbar/>
    
    <Menu v-show="getMenuOpen"/>
    <!-- <Artists/> -->

    <Player/>

    <PopMess v-show="getPopMessOpen"/>

    <!-- <CookieBar/> -->

    <!-- <Loader v-show="getShowLoader"/> -->

  </div>
</template> 

<script>
import { mapGetters } from 'vuex'
import Player from '@/components/player/Player.vue'
import Navbar from '@/components/navigation/Navbar.vue'
import Menu from '@/components/navigation/Menu.vue'
import Artists from '@/components/navigation/Artists.vue'
import PopMess from '@/components/texts/PopMess.vue'
import CookieBar from '@/components/misc/CookieBar.vue'
import Loader from '@/components/misc/Loader.vue'
import LoginBar from '@/components/misc/LoginBar.vue'

export default {
  name: 'App',
  components: {
    Player,
    Navbar,
    Menu,
    Artists,
    PopMess,
    CookieBar,
    Loader,
    LoginBar
  },
  computed: {
    ...mapGetters([
      'getMenuOpen',
      'getPopMessOpen',
      'getShowLoader'
    ]),
  }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
.temp-nav {
  position: fixed;
  bottom: 100px;
}
</style>
