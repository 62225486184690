<template>
<div id="songs-list-block">

    <div id="list" class="p-rel">
        <div :class="{bghover: hoverTitle}">
        </div>
        <div class="list-cont d-f jc-sb w-100 b-bottom m-b p-b bs-bb">
            <div class="d-f">
                <div class="rank-nr">
                    <h1 class="text-outline">
                        <span v-if="!editButtons">#</span>
                        <span>{{number}}</span>
                    </h1>
                </div>
                <div class="img-medium">
                    <img :class="{ 'b-r-100' : isProfiles }" v-if="img" :src="img"/>
                    <div v-else><PlaceholderImg/></div>
                </div>
            </div>
            <div class="d-f fd-c jc-sb w-100">
                <div @mouseenter="hoverTitle = true" @mouseleave="hoverTitle = false">
                        <a class="title-wrap" v-if="isSongs"  @click="$emit('play-item')">
                            <h1 class="d-f w-100 jc-sb">
                                <span class="title m-l" :class="{underline:(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition)}">{{title}}</span> 
                                <!-- <span class="play text-outline" v-show="(activePlayer.currentTrackId === count) !== true">PLAY</span> -->
                                <!-- <span class="playing text-outline" v-show="(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition)">PLAYING</span> -->
                                <span class="play text-outline">{{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'PLAYING' : 'PLAY'}}</span>

                                <!-- <span class="play text-outline">PLAY</span> -->
                                <!-- <span class="play text-outline">{{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'PLAYING' : 'PLAY'}}</span> -->

                                <!-- <h1 :class="(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'playlist-track active-color' : 'playlist-track inactive-color'">{{title}}</h1> -->
                                <!-- <span>{{(activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition) ? 'Playing' : 'Not playing'}}</span> -->
                                <!-- <span v-if="((activePlayer.currentTrackId === count) && (activePlaylist.position === playlistPosition)) && playerIsLoading">
                                    Loading...
                                </span>  -->
                            </h1>
                        </a>
                        <router-link v-if="isProfiles" :to="`/${song.userSettings.usernameUrl}`">
                            <h1 class="m-l">{{title}}</h1>
                        </router-link>
                </div>
                <div class="d-f tt-up jc-fe m-l" v-if="!editButtons">
                    <!-- <div>
                        <div class="grey" v-if="isSongs">{{genre}}</div>
                    </div> -->
                    <div class="d-f tt-up jc-fe">
                        <div>
                            <router-link v-if="isSongs" :to="`/${song.user.usernameUrl}`">Profile</router-link>
                            <router-link v-if="isProfiles" :to="`/${song.userSettings.usernameUrl}`">Profile</router-link>
                        </div>
                        <span class="grey ws-pre"> | </span>
                        <div><a @click="$store.dispatch('togglePopMess')">Follow</a></div>
                    </div>
                </div>
                <div class="d-f tt-up jc-fe" v-if="editButtons">
                    <span class="grey">{{genre}}</span>
                    <span class="grey ws-pre"> | </span>
                    <a @click="deleteSong(song.songUid)">Delete</a>
                </div>
            </div>
        </div>
    </div> 
    
</div>
</template>

<script>
import PlayerMixin from '@/mixins/PlayerMixin'
import PlaceholderImg from '@/assets/icons/PlaceholderImg.vue'

export default {
    name: 'PlaylistBlock',
    props: ['song', 'count', 'playlistPosition', 'editButtons', 'listType2', 'count'],
    mixins: [PlayerMixin],
	components: {
		PlaceholderImg,
	},
    data() {
        return {
            number: '',
            title: '',
            img: '',
            genre: '',
            hoverTitle: false,
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
      isProfiles() {
          return (this.listType2 === 'profiles') ? true : false;
      },
      isSongs() {
          return (this.listType2 === 'songs') ? true : false;
      }
    },
    methods: {
        getImg(x) {
            if (x) {
                return x
            } else {
                return this.$store.state.placeholderImg
            }
        },  
        getData() {
            if (this.listType2 === 'songs') {
                // this.number = (this.song.rankTotal) ? '#' + this.song.rankTotal : this.count + 1;
                this.number = this.count + 1;
                this.title = this.song.title
                this.img = this.song.cover
                this.genre = this.song.genres.gen1
            }
            if (this.listType2 === 'profiles') {
                // this.number = (this.song.rankTotal) ? '#' + this.song.rankTotal : this.count + 1;
                this.number = this.count + 1;
                this.title = this.song.userSettings.username
                this.img = this.song.userSettings.profileImg
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#songs-list-block {

    .list-cont {
        .rank-nr {
            width: calc(25vw - 127px);
            @media #{$mobile} {
                width: 15vw;
            }
        }
    }

    .playlist-track {
    position: relative;
    display: flex;
    .track-duration {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 40;
    }
    .track-duration-hide {
        display: none;
    }
    .buffering-icon {
        position: absolute;
        right: 0;
    }
    }
    .active-color {
    background: rgba(0,0,0, .2);
    }
    .bghover {
        position: absolute;
        width: 100%; 
        background-color: rgba(255,255,255, .05);
        z-index: -1;
        box-sizing: border-box;
        height: 3em;
        @media #{$mobile} {
            height: 2em;
        }
    }

    .title-wrap {
        text-decoration: none;
        .play {
            display: none;
        }
        &:hover {
            .title {
                text-decoration: underline;
            }
            .play {
                display: block;
            }
        }
    }

}
</style>
