<template>
  <div id="footer">
      <div class="w-100 h-100vh bc-brand d-f fd-c ai-c jc-c tt-up p bs-bb">
          
          <!-- <div class="d-f fd-c ai-c">
            <div><span class="grey">Follow  & Feedback</span></div>
            <div class="d-f fd-r ai-c">
              <div class="m-r"><a class="" href="https://discord.gg/R8HXGjPDFb" target="_blank">Discord</a></div> 
              <div class="m-r"><a class="" href="https://www.instagram.com/algorhythm.world/" target="_blank">Instagram</a></div>
              <div><a class="" href="https://x.com/AlgorhythmFM" target="_blank">X.com</a></div>
            </div>
          </div>
          <br> -->
          <div><a @click="scrollToTop()">Scroll to top</a></div>
          <div><span class="grey">© 2025</span></div>

      </div>
  </div>
</template>

<script>
export default {
    name: 'Footer',
    data () {
        return {
        }
    },
    methods: {
      scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
}
</script>
<style lang="scss" scoped>
</style>