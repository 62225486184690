import Vue from 'vue'
import Vuex from 'vuex'
import { rtdb, auth, fb, storage } from '../db.js'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: false,
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    // user: null,
    userSettings: false,
    menuOpen: false,
    popMessOpen: false,
    popMessText: 'Function under construction',
    placeholderImg: 'https://firebasestorage.googleapis.com/v0/b/algorhythm-306411.appspot.com/o/global%2Favatars%2FAlgoSmileyOutlineWhite.png?alt=media&token=1de918e7-b0ff-45e9-8a10-332d00142b63',
    playerHidden: true,
    showLoader: false,

    /*PLAYER START */  

    playlists: [],
    activePlaylist: {},

    players: [],
    activePlayer: {},
    playerObjectAnatomy: {
      //bools - playback helpers
      isPlaying: false,
      isPaused: false,
      isStopped: false,
      isFirstTrack: true,
      isMuted: false,
      playerIsLoading: false,
      continuousPlaybackStatus: false,
      // player element
      audio: new Audio(),
      // player properties
      volume: 0.5,
      buffered: 0,
      // custom helper properties
      currentTrackId: 0,
      currentTrackTime: 0,
      currentTrackDuration: 0,
    }

    /*PLAYER END */
  },

  getters: {
    getUser: (state) => state.user,
    getUserSettings: (state) => state.userSettings,
    getMenuOpen: (state) => state.menuOpen,
    getPopMessOpen: (state) => state.popMessOpen,
    getPlayerHidden: (state) => state.playerHidden,
    getShowLoader: (state) => state.showLoader,
    
    /*PLAYER START */

    getSongs: (state) => (playlistPosition) => state.playlists[playlistPosition].songs,
    getSongsCount: (state) => (playlistPosition) => state.playlists[playlistPosition].count,
    getAudio: (state) => (playerPosition) => state.players[playerPosition].audio,
    getVolume: (state) => (playerPosition) => state.players[playerPosition].volume,
    getCurrentTrackId: (state) => (playerPosition) => state.players[playerPosition].currentTrackId,
    getCurrentTrackTime: (state) => (playerPosition) => state.players[playerPosition].currentTrackTime,
    getCurrentTrackDuration: (state) => (playerPosition) => state.players[playerPosition].currentTrackDuration,
    getPlayerIsLoading: (state) => (playerPosition) => state.players[playerPosition].playerIsLoading,
    getPlayerIsPlaying: (state) => (playerPosition) => state.players[playerPosition].isPlaying,
    getPlayerIsPaused: (state) => (playerPosition) => state.players[playerPosition].isPaused,
    getPlayerIsStopped: (state) => (playerPosition) => state.players[playerPosition].isStopped,
    getContinuousPlaybackStatus: (state) => (playerPosition) => state.players[playerPosition].continuousPlaybackStatus,

    /*PLAYER END */
  },

  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    // setUser(state, payload) {
    //   state.user = payload
    // },
    SET_USER_SETTINGS(state, payload) {
      state.userSettings = payload
    },
    SET_MENU_OPEN(state, payload) {
      state.menuOpen = payload
    },
    SET_POPMESS_OPEN(state, payload) {
      state.popMessOpen = payload
    },
    SET_PLAYER_HIDDEN(state, payload) {
        state.playerHidden = payload
    },
    SET_LOADER(state, payload) {
      state.showLoader = payload
    },

    /*PLAYER START */

    updatePlaylistSongs(state, payload) {
      state.playlists[payload.playlistPosition].songs = payload.songs
      state.playlists[payload.playlistPosition].count = state.playlists[payload.playlistPosition].songs.length
    },
    async addPlaylist(state, payload) { // {title, songs}
      if (payload.songs) { // check if playlist to be added has songs
        await state.playlists.push({
          title: payload.title || `playlist ${state.playlists.length + 1}`,
          songs: payload.songs,
          count: payload.songs.length
        })
        // update player objects
        await state.players.push(state.playerObjectAnatomy)

        // add active player & playlist
        state.activePlaylist = { position: 0, ...state.playlists[0] }
        state.activePlayer = { position: 0, ...state.players[0] }
      } else {
        console.log("Playlist has no songs, you can not add an empty playlist")
      }
    },

//CUSTOM START    

    async replacePlaylist(state, payload) { // {title, songs}
      if (payload.songs) { // check if playlist to be added has songs
        await state.playlists.splice(0, state.playlists.length, {
          title: payload.title || `playlist ${state.playlists.length + 1}`,
          songs: payload.songs,
          count: payload.songs.length
        })
        // update player objects
        await state.players.push(state.playerObjectAnatomy)

        // add active player & playlist
        state.activePlaylist = { position: 0, ...state.playlists[0] }
        state.activePlayer = { position: 0, ...state.players[0] }
      } else {
        console.log("Playlist has no songs, you can not add an empty playlist")
      }
    },
    
// updatePlaylistAsync(state, payload) {
//   return new Promise((resolve) => {
//     if (payload.songs) { // check if playlist to be added has songs
//       state.playlists = [{
//         title: payload.title,
//         songs: payload.songs,
//         count: payload.songs.length
//       }]
//       // update player objects
//       state.players.push(state.playerObjectAnatomy)

//       // add active player & playlist
//       state.activePlaylist = { position: 0, ...state.playlists[0] }
//       state.activePlayer = { position: 0, ...state.players[0] }
//     } else {
//       console.log("Playlist has no songs, you can not add an empty playlist")
//     }
//     resolve(payload.songs)
//     }, 10)
  
// },

//CUSTOM END

    removePlaylist(state, payload) {
      if (state.playlists.length > 0) { // check if at least one playlist exists
        // if position is not specified remove the last added playlist
        if (payload.playlistPosition !== undefined) {
          state.playlists.splice(payload.playlistPosition, 0)
          state.players.splice(payload.playlistPosition, 0)
        } else {
          state.playlists.pop()
          this.players.pop()
        }
      }
    },
    updateActivePlayer(state, payload) {
      state.activePlayer = { position: payload.playerPosition, ...state.players[payload.playerPosition] }
    },

    updateContinuousPlaybackStatus(state, payload) {
      state.players[payload.playerPosition].continuousPlaybackStatus = payload.status
    },
    updateCurrentTrackId(state, payload) {
      state.players[payload.playerPosition].currentTrackId = payload.trackId
    },
    updateCurrentTrackTime(state, payload) {
      state.players[payload.playerPosition].currentTrackTime = payload.time
    },
    updateCurrentTrackDuration(state, payload) {
      state.players[payload.playerPosition].currentTrackDuration = payload.time || 0 // set duration to zero when trackTime is undefined(when track hasn't loaded)
    },
    updatePlayerVolume(state, payload) {
      // Change volume
      state.players[payload.playerPosition].volume = payload.volume
      state.players[payload.playerPosition].audio.volume = payload.volume
    },
    updatePlayerIsLoading(state, payload) {
      state.players[payload.playerPosition].playerIsLoading = payload.status
    },
    updatePlayerIsPlaying(state, payload) {
      state.players[payload.playerPosition].isPlaying = payload.status
    },
    updatePlayerIsPaused(state, payload) {
      state.players[payload.playerPosition].isPaused = payload.status
    },
    updatePlayerIsStopped(state, payload) {
      state.players[payload.playerPosition].isStopped = payload.status
    },
    playTrack(state, payload) {
      // if currentTrackTime is not 0, and if in the same playlist resume play
      // if ((state.players[payload.playerPosition].currentTrackTime !== 0) && state.players[payload.playerPosition].isPaused && (payload.playerPosition === state.activePlayer.position)) {
      if ((state.players[payload.playerPosition].currentTrackTime !== 0) && state.players[payload.playerPosition].isPaused && (payload.playerPosition === state.activePlayer.position) && (payload.trackId === state.activePlayer.currentTrackId)) {
        state.players[payload.playerPosition].audio.play()
      } else {
        // abort current player progress
        state.players[payload.playerPosition].audio.load()

        state.players[payload.playerPosition].playerIsLoading = true // show player loading animation on UI

        state.players[payload.playerPosition].currentTrackId = payload.trackId === 0 || payload.trackId ? payload.trackId : state.players[payload.playerPosition].currentTrackId // update current track id
        state.players[payload.playerPosition].audio.src = state.playlists[payload.playerPosition].songs[state.players[payload.playerPosition].currentTrackId].audio
        state.players[payload.playerPosition].audio.play() // play audio
      }
      state.players[payload.playerPosition].audio.volume = state.players[payload.playerPosition].volume

      // update activePlaylist
      state.activePlaylist = { position: payload.playerPosition, ...state.playlists[payload.playerPosition] }
      state.activePlayer = { position: payload.playerPosition, ...state.players[payload.playerPosition] }
    },
    pauseTrack(state, payload) {
      state.players[payload.playerPosition].audio.pause()
    },
    stopTrack(state, payload) {
      state.players[payload.playerPosition].audio.load()
    },
    seekPlayer(state, payload) {
      // seek to time
      state.players[payload.playerPosition].audio.currentTime = payload.time
    },

    /*PLAYER END */
  },

  actions: {
    fetchUser({ commit }, payload) {
      commit("SET_LOGGED_IN", payload !== null);
      if (payload) {
        commit("SET_USER", {
          uid: payload.uid,
          displayName: payload.displayName,
          email: payload.email,
        });
      } else {
        commit("SET_USER", null);
      }
    },
    // setUser({commit}, payload) {
    //   commit('setUser', payload);
    // },
    fetchUserSettings({ state, commit, dispatch }, user) {
      if (user) {
        rtdb.ref(`userSettings/${user.uid}`).once('value', (snap) => {
          if (snap.val()) {
            const userSettings = snap.val()
            commit('SET_USER_SETTINGS', userSettings)
          } else {
            console.log('Cant find previous userSettings')
          }
        }).then(() => {
          console.log('UserSettings fetched')
        }).catch(err => { console.log(err) })
      } else {
        console.log('No User in payload')
      }
    },
    setUserSettings({ state, commit, dispatch }, settings) {
      if (settings) {
        commit('SET_USER_SETTINGS', settings)     
        console.log('settings set');   
      } else {
        console.log('No settings in payload')
      }
    },
    // setUserSettings({ state, commit, dispatch }, settings) {
    //   if (payload) {
    //     rtdb.ref(`userSettings/${payload.uid}`).once('value', (snap) => {
    //       if (snap.val()) {
    //         const userSettings = snap.val()
    //         commit('SET_USER_SETTINGS', userSettings)
    //       } else {
    //         console.log('Cant find previous userSettings')
    //       }
    //     }).then(() => {
    //       console.log('UserSettings set')
    //     }).catch(err => { console.log(err) })
    //   } else {
    //     console.log('No User in payload')
    //   }
    // },
    resolveUserSettings({ commit }, payload) {
      return new Promise(resolve => {
        rtdb.ref(`userSettings/${payload.uid}`).once('value', (snap) => {
          if (snap.val()) {
            resolve(snap.val())
          } else {
            console.log('No user settings')
          }
        })
      })
    },
    updateRankProfiles({ state, commit, dispatch }, payload) {
      if (payload.changeVal) {
          let user = payload.user
          let ref = rtdb.ref(`rankProfiles/${user.uid}`)
          let updatedUserSettings = payload.changeVal
          ref.update({
            userSettings: updatedUserSettings
          }).then(() => {
            console.log('Rank Profiles updated')
          }).catch((err) => { console.log(err) })
      } else {
        console.log('Something went wrong with updateRankProfiles()')
      }
    },
    updateRankSongs({ state, commit, dispatch }, payload) {      
      //UPDATE rankSongs
      if (payload.changeVal.musicRefs) {
        let user = payload.user
        let ref = rtdb.ref('rankSongs')
        let updatedUserSettings = payload.changeVal
        //get all musicrefs from userSettings
        let musicRefs = Object.values(updatedUserSettings.musicRefs)
        let obj = { userSettings: updatedUserSettings }
        //add ownerUid to object
        obj['ownerUid'] = user.uid
        //loop through and update each
        musicRefs.forEach((x) => {
          ref.child(x.uid)
          .update(obj)
          .catch((err) => { console.log(err) })
        })
      } else {
        return
      }
    },
    logout() {
      auth.signOut().then(() => {
        console.log('Logged Out')
        router.go()
      })
    },
    playInteraction({ state }, payload) {
      const ref = rtdb.ref(`interactions/${payload.userUid}/${payload.songUid}`)
      const userUid = state.user.data.uid
      // ref.child(`${userUid}/plays`)
      // .set(fb.database.ServerValue.increment(1))
      ref.child(userUid)
      .update({ 
        plays: fb.database.ServerValue.increment(1)
      })
      .then(() => {
        console.log('Interaction logged: +1 plays');
      })
      .catch((err) => { console.log(err) })
    },
    likeInteraction({ state, dispatch }, payload) {
      if(state.user.loggedIn) {
        const ref = rtdb.ref(`interactions/${payload.userUid}/${payload.songUid}`)
        const userUid = state.user.data.uid
        // ref.child(userUid).once('value', (snap) => {
        //   if (!snap.exists() || !snap.val().like) {
        //     ref.child(userUid)
        //       .update({ 
        //         like: true
        //       })
        //       .then(() => { console.log('Interaction logged: Liked') })
        //       .catch((err) => { console.log(err) })
        //   } else {
        //     ref.child(userUid)
        //       .update({ 
        //         like: false 
        //       })
        //       .then(() => { console.log('Interaction logged: Unliked'); })
        //       .catch((err) => { console.log(err) })
        //   }
        // })
        ref.child(userUid).update({
          like: fb.database.ServerValue.increment(1)
        }).then(() => { 
          console.log('Interaction logged: Liked') 
        })
        .catch((err) => { console.log(err) })
      } else {
        dispatch('togglePopMess', 'Login to Like')
      }
    },
    onUserMusicUpdate({state, dispatch}, user) {
      //Add music ref id when user uploads music
      const userMusicRef = rtdb.ref("userMusic").child(user.uid)
      const userSettingsMusicRefs = rtdb.ref(`userSettings/${user.uid}/musicRefs`)
      userMusicRef.on('child_added', function (data) {
        //Add userSettingsMusicRefs
        const key = data.key
        console.log(key)
        userSettingsMusicRefs
        .child(key)
        .update({
            uid: key
        })
        .then(() => { console.log('userSettings MusicRef added ') })
        .catch((err) => { console.log(err) })
      })
      userMusicRef.on('child_removed', function (data) {
        //Remove userSettingsMusicRefs when user removes music
        const key = data.key
        userSettingsMusicRefs
          .child(key)
          .remove()
          .then(() => { console.log('userSettings MusicRef removed') })
          .catch((err) => { console.log(err) })
        //Remove rankSongs when user removes music
        const rankSongsRef = rtdb.ref("rankSongs")
        rankSongsRef
          .child(key)
          .remove()
          .then(() => { console.log('rankSongsRef removed') })
          .catch((err) => { console.log(err) })
        //Delete storage folder
        let ref = storage.ref(`userMusic/${user.uid}/${key}`);
        ref.listAll().then(dir => {
          dir.items.forEach(fileRef => {
            var dirRef = storage.ref(fileRef.fullPath);
            dirRef.getDownloadURL().then(function (url) {
              var imgRef = storage.refFromURL(url);
              imgRef.delete().then(function () {
                // File deleted successfully 
                console.log('File deleted successfully: ', url);
              }).catch(function (error) {
                // There has been an error      
                alert('error with delete storage: ', error)
              });
            });
          });
        }).catch(error => {
          console.log(error);
        });
      })
    },
    onUserSettingsUpdate({ state, dispatch, commit }, user) {
      //This is so I dont have to call setUserSettings every time something is added to userSettings
      const userSettingsRef = rtdb.ref("userSettings").child(user.uid)
      userSettingsRef.on('value', function (snap) {
        // return dispatch('setUserSettings', user)
        let change = {
          user: user,
          changeVal: snap.val()
        }
        commit('SET_USER_SETTINGS', change.user)
        dispatch('updateRankProfiles', change)
        dispatch('updateRankSongs', change)

      })
    },
    deleteSong({state}, payload) {
      const userMusicRef = rtdb.ref("userMusic").child(state.user.data.uid)
      userMusicRef
        .child(payload)
        .remove()
        .then(() => { 
          console.log('userMusic deleted:', payload) 
          router.go(router.currentRoute)
        })
        .catch((err) => { console.log(err) })
    },
    togglePopMess({state, commit}, payload) {
      if (!state.popMessOpen) {
        commit('SET_POPMESS_OPEN', true)
        state.popMessText = payload
        setTimeout(() => {
          commit('SET_POPMESS_OPEN', false)
        }, 2000);
      }
    },
    getUserInteractions({ state }, payload) {
      return new Promise((resolve, reject) => {
        const ref = rtdb.ref(`interactions/${payload.userUid}/${payload.songUid}`)
        const userUid = state.user.data.uid
        ref.child(userUid).once('value', function (snap) {
          if (snap.val()) {
            let plays = ( snap.val().plays ) ? true : false;
            let like = ( snap.val().like % 2 ) ? true : false;
            let obj = {
              plays,
              like
            }
            resolve( obj )
          } else {
            reject()
          }
        })
        .then(() => {
        })
        .catch((err) => { console.log(err) })
      })
    },
    hidePlayer({state, commit}) {
      if(state.playerHidden) {
        console.log('true, set false');
        commit('SET_PLAYER_HIDDEN', false)
      } else {
        console.log('false, set true');
        commit('SET_PLAYER_HIDDEN', true)
      }
    },

    /*PLAYER START */

    //CUSTOM START
    replacePlaylist({ commit }, payload) {
      return commit('replacePlaylist', payload)
    },
    updatePlaylistAsync({ commit }, payload) {
      return commit('updatePlaylistAsync', payload)
    },
    //CUSTOM END

    updatePlaylist({ commit }, payload) {
      return commit('updatePlaylistSongs', { payload: payload });
    },
    addPlaylist({ commit }, payload) {
      return commit('addPlaylist', payload)
    },
    play({ commit }, payload) {
      return commit('playTrack', payload) // {playerPosition
    },
    pause({ commit }, payload) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit('pauseTrack', payload); // { playerPosition
          resolve()
        }, 10)
      })
      // return commit('pauseTrack', payload) // {playerPosition
    },
    stop({ state, commit }) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit('stopTrack', { playerPosition: state.activePlayer.position }); // {playerPosition }
          resolve()
        }, 10)
      })
    },
    seek({ state, commit, dispatch }, payload) {
      return dispatch('registerSeek', payload)
        .then(() => {
          commit('updateActivePlayer', { playerPosition: state.activePlayer.position })
        })
    },
    registerSeek({ commit }, payload) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit('seekPlayer', payload); // {playerPosition, time}
          resolve()
        }, 10)
      })
    },
    next({ commit, state }, payload) { // {playerPosition}
      // check if there's a next track on the playlist
      
        if ((state.players[payload.playerPosition].currentTrackId + 1) <= (state.playlists[payload.playerPosition].count - 1)) {
          // play next song
          commit('updateCurrentTrackId', { trackId: (state.players[payload.playerPosition].currentTrackId + 1), ...payload })
          setTimeout(() => {
            commit('playTrack', { trackId: state.players[payload.playerPosition].currentTrackId, ...payload });
          }, 10)
        } else {
          // check if continuous playback is true
          // TODO convert this, to repeat all check instead
            if (state.players[payload.playerPosition].continuousPlaybackStatus) {
              // play first track
              commit('updateCurrentTrackId', { trackId: 0, ...payload })
              setTimeout(() => {
                commit('playTrack', { trackId: state.players[payload.playerPosition].currentTrackId, ...payload });
              }, 10)
            } else {
              // Reached end of playlist
            }
          }
    },
    previous({ commit, state }, payload) { // {playerPosition, playlistPosition}
      // check if there's a previous track on the playlist
        if ((state.players[payload.playerPosition].currentTrackId - 1) >= 0) {
          // play previous song
          commit('updateCurrentTrackId', { trackId: (state.players[payload.playerPosition].currentTrackId - 1), ...payload })
          setTimeout(() => {
            commit('playTrack', { trackId: state.players[payload.playerPosition].currentTrackId, ...payload });
          }, 10)
        } else {
          // check if continuous playback is true
          // TODO convert this, to repeat all check instead
            if (state.players[payload.playerPosition].continuousPlaybackStatus) {
              // play last song
              commit('updateCurrentTrackId', { trackId: (state.playlists[payload.playlistPosition].count - 1), ...payload })
              setTimeout(() => {
                commit('playTrack', { trackId: state.players[payload.playerPosition].currentTrackId, ...payload });
              }, 10)
            } else {
              // Reached start of playlist
            }
          }
    },
    repeat({ commit }, payload) { // {trachId, playerPosition}
      return new Promise((resolve) => {
        setTimeout(() => {
          commit('playTrack', payload);
          resolve()
        }, 10)
      })
    },
    changeContinuousPlay({ state, commit }, payload) { // {playerPosition, status}
      return commit('updateContinuousPlaybackStatus', { playerPosition: state.activePlayer.position, status: payload });
    },
    changeCurrentTrackId({ commit }, payload) { // {playerPosition, trackId}
      return commit('updateCurrentTrackId', payload);
    },
    changeCurrentTrackTime({ commit }, payload) { // {playerPosition, time}
      return commit('updateCurrentTrackTime', payload);
    },
    changeCurrentTrackDuration({ commit }, payload) { // {playerPosition, time}
      return commit('updateCurrentTrackDuration', payload);
    },
    changePlayerIsLoading({ commit }, payload) { // {playerPosition, status}
      commit('updatePlayerIsLoading', payload)
    },
    changePlayerIsPlaying({ commit }, payload) { // {playerPosition, status}
      commit('updatePlayerIsPlaying', payload)
    },
    changePlayerIsPaused({ commit }, payload) { // {playerPosition, status}
      commit('updatePlayerIsPaused', payload)
    },
    changePlayerIsStopped({ commit }, payload) { // {playerPosition, status}
      commit('updatePlayerIsStopped', payload)
    },
    changeVolume({ state, commit }, payload) { // {playerPosition, volume}
      commit('updatePlayerVolume', { playerPosition: state.activePlayer.position, volume: payload })
    },
    updateActivePlayer({ commit }, payload) {
      return commit('updateActivePlayer', payload)
    },

    /*PLAYER END */
  }
})

