<template>
<div id="search-big" class="medium-m-b">

    <div class="d-f fd-c w-100 p bs-bb">
        <div class="d-f jc-fe">
            <!-- <div class="hover">
                <IconBase w="16" h="16" color="black" icon-name="cancel"><IconCancel/></IconBase>
            </div> -->
        </div>
        <div class="input-wrap d-f w-100 m-b ai-fe">
            <input 
            class="b-s-input h1 tt-up"
            placeholder="Find your local artists..."
            @click="$store.dispatch('togglePopMess')"
            />
        </div>

        <div class="buttons-wrap d-f ws-pre ai-c jc-fe m-b" >
            <a class="tt-up d-f ai-c" @click="$store.dispatch('togglePopMess')">
                <span>Search</span>
                <!-- <IconBase w="16" h="16" color="grey"><IconSearch/></IconBase> -->
            </a>
            <span class="grey o-5 ws-pre"> | </span>
            <a class="tt-up d-f ai-c" @click="$store.dispatch('togglePopMess')">
                <span>Use my location</span>
                <!-- <IconBase w="16" h="16" color="grey"><IconSearch/></IconBase> -->
            </a>
        </div>

    </div>

</div>
</template>

<script>
import IconBase from '@/assets/icons/IconBase.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'
import IconSearch from '@/assets/icons/IconSearch.vue'

export default {
    name: 'SearchBig',
	components: {
		IconBase,
		IconCancel,
        IconSearch
	},
    data () {
        return {
            showSearchBig: true
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#search-big {
    position: relative;
    z-index: 0;
    .input-wrap {
        border-bottom: $border-small solid $white;
        &:hover {
            // border-bottom: $border-small solid $brand-hl;
            background-color: rgba(250, 250, 250, 0.05);
        }
        .b-s-input {
            width: 100%;
            padding: 0;
            border: none !important;
            border-radius: 0;
            color: $white;
            letter-spacing: -2px;
            &:focus {
                outline: none;
            }
        }
    }
} 
</style>