<template>
<div id="playlist" class="p-l p-r">

    <div class="b-bottom m-b p-b">
        <h1 class="tt-up" v-if="title">{{title}}</h1>
        <div class="d-f jc-sb">
            <p class="tt-up">
                <router-link v-if="subTitle && subLink" :to="{ path: `${subLink}` }">{{subTitle}}</router-link>
                <span v-if="subTitle && !subLink">{{subTitle}}</span>
            </p>
            
            <div class="tt-up d-f">
                <div class="view-link grey" :class="{ strike : viewLocal === 'list'}" @click="viewLocal = 'list'">List</div>
                <span class="grey ws-pre"> | </span>
                <div class="view-link grey" :class="{ strike : viewLocal === 'grid'}" @click="viewLocal = 'grid'">Grid</div>
            </div>
        </div>
    </div>
    
    <div v-if="listType === 'profiles'">
        <div class="grid-wrapper b-bottom m-b" v-show="viewLocal === 'grid'">
            <PlaylistBlockGrid
                v-for="(song, key) in playlist.users" 
                :song="song" 
                :count="key" 
                :key="key" 
                :editButtons="editButtons"
                :listType2="listType"
                >
            </PlaylistBlockGrid>
        </div>

        <div v-show="viewLocal === 'list'">
            <PlaylistBlock
                v-for="(song, key) in playlist.users" 
                :song="song" 
                :count="key" 
                :key="key" 
                :editButtons="editButtons"
                :listType2="listType"
                >
            </PlaylistBlock>
        </div>
        <p class="loading-dots tt-up" v-show="!playlist.users">Loading artists</p>
    </div> 
    <div v-if="listType === 'songs'">

        <div class="grid-wrapper b-bottom m-b" v-show="viewLocal === 'grid'">
            <PlaylistBlockGrid
                v-for="(song, key) in playlist.songs" 
                :song="song" 
                :count="key" 
                :key="key" 
                :playlist-position="playlistPosition" 
                @play-item="playSelectedSongAsync({playerPosition: playlistPosition, trackId: key}, playlist)"
                :editButtons="editButtons"
                :listType2="listType"
                >
            </PlaylistBlockGrid>
        </div>
        <div v-show="viewLocal === 'list'">
            <PlaylistBlock
                v-for="(song, key) in playlist.songs" 
                :song="song" 
                :count="key" 
                :key="key" 
                :playlist-position="playlistPosition" 
                @play-item="playSelectedSongAsync({playerPosition: playlistPosition, trackId: key}, playlist)"
                :editButtons="editButtons"
                :listType2="listType"
                >
            </PlaylistBlock>
        </div>
        <p class="loading-dots tt-up" v-show="!playlist.songs">Loading songs</p>
    </div>

</div>
</template>

<script>
import PlaylistBlock from '@/components/playlists/PlaylistBlock'
import PlaylistBlockGrid from '@/components/playlists/PlaylistBlockGrid'
import PlayerMixin from '@/mixins/PlayerMixin'

export default {
    name: 'Playlist',
    components: {
        PlaylistBlock,
        PlaylistBlockGrid
    },
    props: ['playlist', 'playlistPosition', 'editButtons', 'listType', 'title', 'subTitle', 'view', 'subLink'],
    mixins: [PlayerMixin],
    data() {
        return {
            viewLocal: this.view
        }
    },
    methods: {
        playSelectedSongAsync(payload, playlist) {
            const that = this
            async function asyncFunc() {
                await that.replacePlaylist({
                    title: 'Playlist',
                    songs: playlist.songs
                })
                that.playSelectedSong(payload)
                console.log(payload);
            }
            return asyncFunc()
        }   
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#playlist {
    .view-link {
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .grid-wrapper {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: $mg-desktop;   
        @media #{$mobile} { 
            grid-template-columns: repeat(2, 1fr);
            box-sizing: border-box;
            padding: 0;
            gap: $mg-mobile;   
        }
    }
}

</style>
