<template>
  <div id="profiles-list" class="p big-m-b">
    <div class="b-bottom m-b p-b">
        <h1 class="fw-b">{{title}}</h1>
        <div class="d-f jc-sb">
            <p class="tt-up">{{subTitle}}</p>
            <div>
                <a class="tt-up strike grey">List</a>
                <span class="grey ws-pre"> | </span>
                <a class="tt-up">Grid</a>
            </div>
        </div>
    </div>
    <div v-for="x in list" :key="x">
        <!-- {{x}} -->
        <div class="list-cont d-f jc-sb w-100 b-bottom m-b p-b">
            <div class="d-f">
                <div class="rank-nr">
                    <h1 class="fw-b">#{{x.rankTotal}}</h1>
                </div>
                <div class="profile-img">
                    <img 
                    v-if="x.userSettings.profileImg"
                    :src="x.userSettings.profileImg"/>
                    <img v-else src="" alt="">
                </div>
            </div>
            <div class="d-f fd-c jc-sb w-100">
                <div class="m-l">
                    <router-link :to="`/${x.userSettings.usernameUrl}`">
                        <h1 class="fw-b">{{x.userSettings.username}}</h1>
                    </router-link>
                </div>
                <div class="d-f tt-up jc-fe">
                    <!-- <div class="m-l"><a>Play latest</a></div> -->
                    <div><a>Profile</a></div>
                    <span class="grey ws-pre"> | </span>
                    <div><a>Follow</a></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { rtdb } from '@/db.js'
import { mapGetters } from "vuex"

export default {
  name: 'ProfilesList',
  props: ['title', 'subTitle', 'listRef', 'childRef'],
  data() {
	  return {
        list: null
	  }
  },
  mounted() {
    this.getList()
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getUserSettings',
    ]),
  },
  methods:{
    getList() {
        if(this.listRef) {
        const ref = rtdb.ref(this.listRef)
        ref.on('value', (snapshot) => {
            this.list = snapshot.val()    
        })
        }
  },

    
  }
}
</script>
<style lang="scss" scoped>
#profiles-list {
    .list-cont {
        .profile-img {
            img {
                width: 100px;
                height: 100px;
            }
        }
        .rank-nr {
            width: 200px;
        }
    }
}
</style>