<template>
  <div v-if="activePlaylist && (activePlaylist.songs.length > 0)" class="mp-wrap">
    <div class="mp-player-wrap" :style="'background: url(' + activePlaylist.songs[activePlayer.currentTrackId].cover + ');background-repeat: no-repeat;background-position:center;background-size: cover'">
      <div class="play-controls">
        <div class="">
          <div class="">
            <v-circle :percent="(activePlayer.currentTrackTime/activePlayer.currentTrackDuration * 100)" stroke-width="6" stroke-linecap="round" :strokeColor="progressColor"/>
          </div>
          <div class="player-buttons">
            <div class="">
              <span @click="playPrevSong()">
                <SkipBackwardIcon class="icon" w="30" h="30" />
              </span>
            </div>
            <div class="">
              <span @click="playCurrentSong()">
                <PlayIcon class="tw-text-white tw-cursor-pointer" v-show="!activePlayer.isPlaying && !activePlayer.playerIsLoading" w="30" h="30"/>
              </span>
              <span @click="pauseSong()">
                <PauseIcon class="tw-text-white tw-cursor-pointer" v-show="activePlayer.isPlaying && !activePlayer.playerIsLoading" w="30" h="30" />
              </span>
              <span>
                <BufferingIcon class="tw-text-white tw-cursor-pointer" animate="rotate" v-show="activePlayer.playerIsLoading" w="30" h="30" />
              </span>
            </div>
            <div class="">
              <span @click="stop()"><SquareIcon class="icon" w="30" h="30" /></span>
            </div>
            <div class="">
              <span @click="playNextSong()">
                <SkipForwardIcon class="icon" w="30" h="30" />
              </span>
            </div>
          </div>
          <div class="track-scrubbing">
            <xns-seek-bar :bar-height="progressHeightMain" :bar-color="progressColor" :bar-shade-color="progressBgColor" :intensity="1" :current-value="activePlayer.currentTrackTime" :total-value="activePlayer.currentTrackDuration" @seekedTo="seekPlayer"></xns-seek-bar>
          </div>
          <div class="track-duration">
            <div class="">{{activePlayer.currentTrackTime | doubleDigits }} - {{activePlayer.currentTrackDuration | doubleDigits}}</div>
          </div>
          <div class="misc-buttons">
              <div class="volume">
                <xns-seek-bar @seekedTo="changeVolume" :bar-height="volumeHeightMain" :bar-color="progressColor" :bar-shade-color="progressBgColor" :intensity="1" :current-value="activePlayer.volume" :total-value="1"></xns-seek-bar>
              </div>
              <span @click="changeContinuousPlay(!activePlayer.continuousPlaybackStatus)" :class="activePlayer.continuousPlaybackStatus ? 'active-color' : 'inactive-color'">
                <RefreshIcon/>
              </span>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(playlist, key) in playlists" :key="playlist">
      <songs-playlist :playlist-position="key" :current-track-id="getCurrentTrackId(key)" @play-select-song="playSelectedSong" :playlist="playlist"></songs-playlist>
    </div>
    {{activePlayer}}

  </div>
</template>

<script>
import Vue from 'vue'

import SongsPlaylist from '@/components/player/SongsPlaylist'

import { VCircle } from 'v-progress'

import RefreshIcon from 'vue-ionicons/dist/md-refresh'
import SkipBackwardIcon from 'vue-ionicons/dist/md-skip-backward'
import PlayIcon from 'vue-ionicons/dist/md-play'
import PauseIcon from 'vue-ionicons/dist/md-pause'
import SquareIcon from 'vue-ionicons/dist/md-square'
import SkipForwardIcon from 'vue-ionicons/dist/md-skip-forward'
import BufferingIcon from 'vue-ionicons/dist/md-refresh'
import PlayerMixin from '@/mixins/PlayerMixin'

import XnsSeekBar from 'xns-seek-bar'
Vue.use(XnsSeekBar)

export default {
  name: 'MainPlayer',
  components: {
    SongsPlaylist, VCircle, RefreshIcon,SkipBackwardIcon, PlayIcon, PauseIcon, SquareIcon, SkipForwardIcon, BufferingIcon
  },
  mixins: [PlayerMixin],
  filters: {
    doubleDigits: function (val) {
      if (isNaN(val)) {
        return '00'
      } else {
        if (val < 60) {
          return val < 10 ? '0 : 0' + val.toFixed() : '0 : ' + val.toFixed()
        } else {
          let seconds = (val % 60).toFixed() == 60 ? '00' : (val % 60).toFixed()
          let minutes = Math.floor(val / 60).toFixed()
          return minutes + ' : ' + (seconds < 10 ? '0' + seconds : seconds)
        }
      }
    },
    secsToMinutes: function (val) {
      if (isNaN(val)) {
        return (val.toFixed() % 60) + ' : ' + Math.floor(val / 60)
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss">
.vue-progress-circle{
  width: fit-content !important;
}
.mp-wrap {
  display: flex;
	.icon {
		fill: white;
	}
  .mp-player-wrap {
    width: 400px;
    .play-controls {
      padding: 10px;
      box-sizing: border-box;
      background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.4), rgba(255, 255, 255, 0.2));
      .player-buttons {
        display: flex;
      }
      .track-scrubbing > div {
        padding: 0 !important;
      }
      .track-duration {
        color: white;
      }
      .misc-buttons {
        display: flex;
        .volume {
          display: flex;
          width: 50%;
        }
				.inactive-color {
					fill: white;
				}
				.active-color {
					fill: green;
				}
      }
    }
  }
}

</style>
