<template>
  <div id="menu" class="p-f t-0 w-100">
    <div class="d-f tt-up">
        <div class="close-menu w-100 h-100vh bc-black-op50" @mouseover="closeMenu(false)"></div>
        <div class="w-100 h-100vh bc-black p-half" @click="closeMenu(false)">
            <div class="d-f jc-fe">
                <div class="hover p" @click="closeMenu(false)">
                    <IconBase w="24" h="24" color="#fff" icon-name="cancel"><IconCancel/></IconBase>
                </div>
            </div>
            <h2 class="d-f fd-c">

                <div v-if="getUser.loggedIn">
                    <div class="link d-f jc-sb"><router-link class="w-100" :to="`/${getUserSettings.usernameUrl}`">Profile</router-link><span>•</span></div>
                    <div class="link d-f jc-sb"><router-link class="w-100 small-m-l" to="/settings">Settings</router-link><span>•</span></div>
                    <div class="link d-f jc-sb"><router-link class="w-100 small-m-l" to="/mymusic">My Tracks</router-link><span>•</span></div>
                    <div class="link d-f jc-sb"><a class="w-100 small-m-l" to="/" @click="logout()" v-if="getUser.loggedIn">Log Out</a><span>•</span></div>
                </div>
                <div v-if="!getUser.loggedIn">
                <div class="link d-f jc-sb"><router-link class="w-100" to="/sign_in">Sign In</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/sign_up">Sign Up</router-link><span>•</span></div>
                </div>

                <br>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/">Home</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/about">About</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/artists/world">Artists</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/music/geo-World">Tracks</router-link><span>•</span></div>
                <div class="link d-f jc-sb"><router-link class="w-100" to="/legal">Legal</router-link><span>•</span></div>
                
                <br>
                <div class="link d-f jc-sb"><a class="grey" href="https://discord.gg/R8HXGjPDFb" target="_blank">Discord</a><span>•</span></div>
                <div class="link d-f jc-sb"><a class="grey" href="https://www.instagram.com/algorhythm.world/" target="_blank">Instagram</a><span>•</span></div>
                <div class="link d-f jc-sb"><a class="grey" href="https://x.com/AlgorhythmFM" target="_blank">X.com</a><span>•</span></div>
                
            </h2>
        </div>  
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import IconBase from '@/assets/icons/IconBase.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

export default {
    name: 'Menu',
	components: {
		IconBase,
		IconCancel
	},
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters([
        'getUser',
        'getUserSettings'
        ]),
    },
    methods: {
        closeMenu(x) {
            return this.$store.commit('SET_MENU_OPEN', x)
        },
        logout() {
            return this.$store.dispatch('logout')
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
#menu {
    z-index: 30;
    .wrap {
        background-color: $brand-bg;
    }
    .close-menu {
      @media #{$mobile} { 
        width: 20vw;
    }
    }
    .link {
        padding: 0px 20px;
        span {display: none;}
        &:hover {
            span {display: block;}
            background-color: rgba(250, 250, 250, 0.05);
        }
        @media #{$mobile} { 
            font-size: 2em;
            letter-spacing: -2px;
        }
    }
}
</style>