<template>
  <div class="home">

    <!-- <DBtesting /> -->

    <!-- <MainPlayer /> -->
    <!-- <Player /> -->

    <LogoSpin/>

    <DescShort/>

    <SearchBig />

    <Top10/>

    <Footer/>

    <!-- <Top10
    :type="'profiles'"
    :title="'Worldwide'"
    :subTitle="'Top Artists'"
    :dbRef="'rankLocations/rankProfiles/top10/World'"
    /> -->

    <!-- <ProfilesList 
    :title="'Worldwide'"
    :subTitle="'Top Artists'"
    :listRef="'rankLocations/rankProfiles/top10/World'"
    />

    <SongsList 
    :title="'Worldwide'"
    :subTitle="'Top Songs'"
    :listRef="'rankLocations/rankSongs/top10/World'"
    /> -->

    <!-- <GetRankList 
    :title="'LOCATIONS PROFILES RANK'"
    :listRef="'rankLocations'"
    :childRef="'rankProfiles'"
    />

    <GetRankList 
    :title="'LOCATIONS SONGS RANK'"
    :listRef="'rankLocations'"
    :childRef="'rankSongs'"
    />

    <GetRankList 
    :title="'GENRES PROFILES RANK'"
    :listRef="'rankGenres'"
    :childRef="'rankProfiles'"
    />

    <GetRankList 
    :title="'GENRES SONGS RANK'"
    :listRef="'rankGenres'"
    :childRef="'rankSongs'"
    /> -->

  </div>
</template>

<script>
import MainPlayer from '@/components/player/MainPlayer'
import Player from '@/components/player/Player.vue'
import DBtesting from '@/components/DBtesting.vue'
import { mapActions } from 'vuex'
import GetRankList from '@/components/toplists/GetRankList.vue'
import DescShort from '@/components/texts/DescShort.vue'
import ProfilesList from '@/components/toplists/ProfilesList.vue'
import SongsList from '@/components/toplists/SongsList.vue'
import Top10 from '@/components/toplists/Top10.vue'
import Footer from '@/components/navigation/Footer.vue'
import LogoSpin from '@/components/misc/LogoSpin.vue'
import SearchBig from '@/components/misc/SearchBig.vue'

export default {
  name: 'Home',
  components: {
    MainPlayer,
    Player,
    DBtesting,
    GetRankList,
    DescShort,
    ProfilesList,
    SongsList,
    Top10,
    Footer,
    LogoSpin,
    SearchBig
  },
    data(){
      return {
        demoPlaylist: [
          { 
            audio: 'https://rorg.z1.fm/d/3f/ti_ft_eminem_-_thats_all_she_wrote_(zv.fm).mp3', 
            artist: 'T.I', 
            title: 'That\'s All She Wrote (ft. Eminem)', 
            // album: '', 
            cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189593/random/f55abc725080eb05147e45ce3cd406a8.1000x1000x1.jpg' 
          },
          { 
            audio: 'https://dll.z1.fm/music/8/e8/ellie_goulding_feat_diplo__swae_lee_-_close_to_me.mp3', 
            artist: 'Ellie Goulding Feat. Diplo & Swae Lee', 
            title: 'Close To Me', 
            // album: 'None', 
            cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189716/random/ellie-goulding-close-to-me-lg.jpg' 
          },
          // { audio: 'https://rorg.z1.fm/8/ff/sia_-_lullaby_zaycevnet_(zv.fm).mp3', artist: 'Sia', title: 'Lullaby', album: '', cover: 'https://res.cloudinary.com/djx5h4cjt/image/upload/v1551189786/random/t54664010-b708389188_s400.jpg' },
        ]
      }
    },
    mounted(){
      // this.replacePlaylist({
      //   title: 'Playlist 1',
      //   songs: this.demoPlaylist
      // })
      // this.addPlaylist({
      //   title: 'Playlist 1',
      //   songs: this.demoPlaylist
      // })
      // this.addPlaylist({
      //   title: 'Playlist 2',
      //   songs: this.demoPlaylist
      // })
    },
    methods: {
      ...mapActions(['addPlaylist', 'replacePlaylist'])
    }
}
</script>
