<template>
  <div id="navbar" class="p-f t-0 w-100 bc-brand">
      <div class="d-f tt-up p-half p-l p-r">
        <div class="w-100"><router-link to="/" :class="{ underline: isActive('Home') }">Algorhythm</router-link></div>

        <div class="d-f jc-c hide-mob">
            <a class="grey d-f" @click="$store.dispatch('togglePopMess')">
                <span>Search</span>
                <!-- <IconBase w="16" h="16" color="grey"><IconSearch/></IconBase> -->
            </a>
        </div>
        <span class="m-l m-r grey o-5 hide-mob">|</span>
        <div class="d-f jc-c hide-mob">
            <router-link class="d-f" :class="{ underline: isActive('ArtistsListId') }" to="/artists/World">
                <span>Artists</span>
            </router-link>
        </div>
        <span class="m-l m-r grey o-5 hide-mob">|</span>
        <div class="d-f jc-c hide-mob">
            <router-link class="d-f" :class="{ underline: isActive('MusicGeoListId') }" to="/music/geo-World">
            <span>Tracks</span>
            </router-link>
        </div>
        <!-- <span class="m-l m-r grey o-5 hide-mob">/</span>
        <div class="d-f jc-c hide-mob">
            <router-link class="d-f" :class="{ underline: isActive('MusicGenreListId') }" to="/music/genre-All Genres">
            <span>Genres</span>
            </router-link>
        </div> -->
        
        <div class="w-100 d-f jc-fe hide-mob">
            <a class="d-f" @mouseover="hoverMenu(true)">
                <span>Menu</span>
            </a>
        </div>
        <div class="w-100 d-f jc-fe hide-desk"><a @click="hoverMenu(true)">Menu</a></div>

      </div>
  </div>
</template>

<script>
import IconBase from '@/assets/icons/IconBase.vue'
import IconArrowDown from '@/assets/icons/IconArrowDown.vue'
import IconSearch from '@/assets/icons/IconSearch.vue'
import IconMenu from '@/assets/icons/IconMenu.vue'

export default {
    name: 'Navbar',
    components: {
        IconBase,
        IconArrowDown,
        IconSearch,
        IconMenu
    },
    data () {
        return {
        }
    },
    computed: {

    },
    methods: {
        hoverMenu(x) {
            return this.$store.commit('SET_MENU_OPEN', x)
        },
        isActive(x, xx) {
            return (this.$route.name === x || this.$route.name === xx) ? true : false
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_classes.scss";
@import "@/scss/_mixins.scss";
</style>