<template>
<div id="desktop-player" @mouseleave="togglePlayer">

	<div class="pp-show-wrap d-f ai-c jc-c w-100">
		<div 
		class="pp-show-button d-f ai-c jc-c m br-r p-half hover" 
		v-if="hidePlayer" 
		@mouseenter="togglePlayer">
			<!-- <IconBase icon-name="chevron-up"><IconChevronUp/></IconBase> -->
			<div class="d-f" :class="{ spinPlay: isPlaying }">
				<img :src="songCover" alt="">
			</div>
		</div>
	</div>

	<div :class="hidePlayer ? 'pp-hide' : 'pp-wrap p bs-bb bc-black'">
		
		<div class="img">
			<img :src="songCover" alt="">
		</div>

		<div class="floor-wrap w-100 m-l m-r d-f fd-c jc-sb">

			<div class="1-floor d-f jc-sb w-100">

				<div class="d-f tt-up w-100">
					<a class="m-r" @click="playPrevSong()">
						Prev
					</a>
					<a class="m-r" @click="playCurrentSong()" v-show="!isPlaying && !isLoading">
						Play
					</a>
					<a class="m-r" @click="pauseSong()" v-show="isPlaying && !isLoading">
						Pause
					</a>
					<a class="m-r" v-show="isLoading">
						Loading...
					</a>
					<a class="" @click="playNextSong()">
						Next
					</a>
				</div>

				<div class="d-f">
					<p class="ws-pre">{{songTitle}}</p>
				</div>

				<div class="d-f jc-fe tt-up w-100">
					<div class="like m-r" @click="likeInteractionLocally(songId)">
						<a v-bind:class="{ strike: userInteractions.like }">
							<span class="grey" v-if="!userInteractions.like">+</span>
							<span class="grey" v-if="userInteractions.like">–</span>
							<span>Like</span>
						</a>
						<!-- <a><span class="grey">+</span>Like</a> -->
					</div>
					<div class="playlist m-r" @click="$store.dispatch('togglePopMess')">
						<a><span class="grey">+</span>List</a>
					</div>
					<div class="comment" @click="$store.dispatch('togglePopMess')">
						<a><span class="grey">+</span>Comment</a>
					</div>
				</div>

			</div>

			<div class="2-floor d-f jc-sb w-100">

				<div class="playing-progress">
					<div v-if="!isNaN(songDuration)" 
					class="track-duration">{{songTime | doubleDigits }}</div>
					<div v-else class="track-duration">{{'0:00'}}</div>

					<ProgressBar
					class="m-l m-r"
					:bar-height="16" 
					:bar-color="'#ffffff'" 
					:bar-shade-color="'#333333'" 
					:intensity="1"
					:listen="true"
					:current-value="songTime" 
					:total-value="songDuration" 
					@seekedTo="seekPlayer"
					>
					</ProgressBar>

					<div v-if="!isNaN(songDuration)" 
					class="track-duration">{{songDuration | doubleDigits}}</div>
					<div v-else class="track-duration">{{'0:00'}}</div>

				</div>
				
				<!-- <div class="volume">
					<ProgressBar
                    class="m-l"
					:bar-height="16" 
					:bar-color="'#ffffff'" 
					:bar-shade-color="'#333333'"
					:intensity="1" 
					:listen="true"
					:current-value="songVolume" 
					:total-value="1"
                    @seekedTo="changeVolume"
                    >
					</ProgressBar>
				</div> -->

			</div>

		</div>

		<div 
			class="pp-hide-button d-f ai-c jc-c" 
			style="cursor: pointer;"
			v-if="!hidePlayer" 
			@click="togglePlayer">
			<IconBase icon-name="chevron-down"><IconChevronDown/></IconBase>
			<!-- <IconBase icon-name="cancel"><IconCancel/></IconBase> -->
		</div>

	</div>
	
</div>
</template>

<script>
// import Vue from 'vue'
// import XnsSeekBar from 'xns-seek-bar'
// Vue.use(XnsSeekBar);
import PlayerMixin from '@/mixins/PlayerMixin'

import IconBase from '@/assets/icons/IconBase.vue'
import IconChevronDown from '@/assets/icons/IconChevronDown.vue'
import IconChevronUp from '@/assets/icons/IconChevronUp.vue'
import IconCancel from '@/assets/icons/IconCancel.vue'

import ProgressBar from '@/components/player/ProgressBar'

import { mapGetters } from "vuex"

export default {
	name: 'DesktopPlayer',
    props: [
		'userInteractions'
    ],
	components: {
		IconBase,
		IconChevronDown,
		IconChevronUp,
		IconCancel,
		ProgressBar,
	},
    mixins: [ PlayerMixin ],
	filters: {
		doubleDigits: function (val) {
		if (isNaN(val)) {
			return '00'
		} else {
			if (val < 60) {
			return val < 10 ? '0:0' + val.toFixed() : '0: ' + val.toFixed()
			} else {
			let seconds = (val % 60).toFixed() == 60 ? '00' : (val % 60).toFixed()
			let minutes = Math.floor(val / 60).toFixed()
			return minutes + ':' + (seconds < 10 ? '0' + seconds : seconds)
			}
			}
		},
		secsToMinutes: function (val) {
		if (isNaN(val)) {
			return (val.toFixed() % 60) + ':' + Math.floor(val / 60)
		} else {
			return 0
		}
		}
	},
    data() {
        return {
            hidePlayer: false
        }
    },
	mounted() {
		this.dbHidePlayer()
	},
    computed: {
		...mapGetters([
			'getUser',
			'getUserSettings'
		]),
        songArtist() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId].artist
        },
        songTitle() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId].title
        },
        songId() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId]
        },
        songCover() {
            return this.activePlaylist.songs[this.activePlayer.currentTrackId].cover
        },
        isLoading() {
            return this.activePlayer.playerIsLoading
        },
        isPlaying() {
            return this.activePlayer.isPlaying
        },
        songDuration() {
            return this.activePlayer.currentTrackDuration
        },
        songTime() {
            return this.activePlayer.currentTrackTime
        },
        songVolume() {
            return this.activePlayer.volume
        },
    },
    methods: {
        reEmitSeekedToPlayer(payload) {
            return this.$emit('seekedToPlayer', payload)
        },
        reEmitSeekedToVolume(payload) {
            return this.$emit('seekedToVolume', payload)
        },
		likeInteractionLocally(songId) {
			this.likeInteraction(songId)
			if (this.getUser.loggedIn) {
				this.userInteractions.like = !this.userInteractions.like
			} else {
				return
			}
		},
		dbHidePlayer() {
			return this.$store.dispatch('hidePlayer')
		},
        togglePlayer() {
            this.hidePlayer = !this.hidePlayer;
            this.$store.dispatch('hidePlayer');
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
#desktop-player {
    .floor-wrap {
        height: calc(#{$p1} * 2.5);
    }
    .pp-show-button {
        position: fixed;
		background-color: $black;
		border: 1px solid $grey;
        bottom: 0;
		padding: 4px;
		border-radius: 100%;
		img {
			width: calc(#{$p1} * 3);
			height: calc(#{$p1} * 3);
			border-radius: 100%;
		}
		&:hover {
			background-color: $brand-bg;
			border: 1px solid $grey;
		}
    }
    .pp-hide-button {
        width: calc(#{$p1} * 2.5);
        height: calc(#{$p1} * 2.5);
    }
    .pp-hide {
        display: none;
    }
    .pp-wrap {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        max-height: 100px;
        z-index: 20;
        position: fixed;
        bottom: 0;
        left: 0;
        .icon {
            fill: white;
        }
        .img {
            img {
                width: calc(#{$p1} * 2.5);
                height: calc(#{$p1} * 2.5);
            }
        }
        .loop {
            .inactive-color {
                color: red;
            }
            .active-color {
                color: green;
            }
        }
        .playing-progress {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .track-duration {
                color: white;
            }
        }
        .volume {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

</style>
